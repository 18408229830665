import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footerConteiner">
        <div className="column">
          <img src="/imgs/geo-alt.svg" alt="" />
          <p>
            <b>Suipacha 936</b>
            <br />
            <a href="https://goo.gl/maps/UhTyZSJn5UWGga1z9">
              Ciudad Autónoma de Buenos Aires - <br /> CP 1008
            </a>
          </p>
        </div>
        <div className="column">
          <img src="/imgs/clock.svg" alt="" />
          <p>
            <b>De lunes a
              sábados</b>
            <br />
            <b>9.00 - 21.00 hs</b>
          </p>
        </div>
        <div className="column">
          <img src="/imgs/envelope.svg" alt="" />
          <p>
            <b>Contacto</b>
            <br />
            <a href="mailto: info@legrandpatron.com.ar"><b>info@legrandpatron.com.ar</b></a>
            <br />
            <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=541168890212&text=Hola%20Le%20Grand%20Patrón%20Salón%20Boutique%2C%20me%20gustaría%20solicitar%20un%20turno%20para..."
              target="_blank"><b>(011) 6889-0212</b></a>
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: 'black', width: '100%', display: 'flex', justifyContent: 'center' }} className="links">
        <Link style={{ color: '#615f5f', textDecoration: 'none' }} to={'/Politica-de-Privacidad'}>Política de privacidad</Link>
      </div>
    </footer>
  );
};

export default Footer;
