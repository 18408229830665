import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useStore } from "../store/Store";

const Confirmation = ({ keyword, StartDay, insertData }) => {
    const { HourSelect } = useStore((state) => state);

    const navigate = useNavigate();
    const [userCalendarAdd, setuserCalendarAdd] = useState(false);

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const InsertEvent = await axios.post(
                "https://lgp-backend-zeta.vercel.app/ClientEventCreate/",
                {
                    code,
                    event: insertData.insertEvent,
                    keyWord: insertData.keyWord,
                }
            );
            if (!InsertEvent.err) setuserCalendarAdd(true);
        },
        flow: "auth-code",
        scope: "https://www.googleapis.com/auth/calendar",
    });

    return (
        <>
            <h3 className="TitleTurnos">Confirmación del turno</h3>
            <h4>Su turno fue reservado con exito.</h4>
            <div className="Aclaracion" style={{ textAlign: "center" }}>
                Al reservar su turno en nuestras instalaciones
                <b>
                    {" "}
                    asume el compromiso de presentarse en el día y horario solicitado.
                </b>{" "}
                Le Grand Patron se reserva los derechos de admisión.
            </div>
            <div className="cardConfirm">
                <div className="ConfirmTitle">SU PALABRA DE ACCESO ES</div>
                <div className="KeyWord">{keyword}</div>
                <div className="bookingDetail">Detalle de la reserva</div>
                <div className="confirmDetalle">
                    {new Date(StartDay).toLocaleDateString("es-ES", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                    })}{" "}
                    a las {HourSelect}hs
                </div>
                <div className="confirmDetalle">
                    Suipacha 936, Ciudad Autónoma de Buenos Aires
                </div>
            </div>
            <div className="conteinerBottons confirmation">
                <button
                    onClick={() => {
                        navigate(0);
                    }}
                    className="back"
                >
                    <Link to={"/"}>Volver al inicio</Link>
                </button>

                {userCalendarAdd ? (
                    <button disabled={true} className="next ">
                        Cita agregada
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            googleLogin();
                        }}
                        className="next googleBrand"
                    >
                        <img src="/imgs/Google_Calendar_icon.png"></img>
                        Añadir cita al calendario
                    </button>
                )}
            </div>
        </>
    );
};

export default Confirmation;
