import { useEffect } from "react";
import { useStore } from "../store/Store";

const Schedule = ({ pageNavigation }) => {
    const { dateState, setPersonsInThatHour, HourSelect, setHourSelect, bookingHours } = useStore((state) => state);
    let horarios = [
        "9:00",
        "9:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
    ];
    let time = new Date().getTime();
    function getNextElement(searchString) {
        const index = horarios.indexOf(searchString);
        if (index !== -1 && index < horarios.length - 1) {
            return horarios[index + 1];
        } else {
            return null; // Si no se encuentra o es el último elemento, devuelve null
        }
    }

    const initialSelectDay = () => {
        if (bookingHours[dateState]) {
            let freeschedule = null;
            for (let i = 0; i < horarios.length; i++) {
                const element = horarios[i];
                if (
                    bookingHours[dateState][`${element}:00-03:00`] === undefined &&
                    time < new Date(`${dateState}T${element}:00-03:00`)
                ) {
                    freeschedule = element;
                    break;
                }
            }
            setHourSelect(freeschedule);

            setPersonsInThatHour(
                bookingHours[dateState][
                    `${freeschedule.length === 4 ? `0${freeschedule}` : `${freeschedule}`
                    }:00-03:00`
                ] !== undefined
                    ? bookingHours[dateState][
                    `${freeschedule.length === 4
                        ? `0${freeschedule}`
                        : `${freeschedule}`
                    }:00-03:00`
                    ]
                    : 0
            );
        } else {
            let freeschedule = null;
            for (let index = 0; index < horarios.length; index++) {
                const element = horarios[index];
                if (
                    time <
                    new Date(
                        `${dateState}T${element.length === 4 ? `0${element}` : `${element}`
                        }:00-03:00`
                    )
                ) {
                    freeschedule = element.length === 4 ? `0${element}` : `${element}`;
                    break;
                }
            }

            setPersonsInThatHour(
                bookingHours[dateState]
                    ? bookingHours[dateState][
                        `${freeschedule.length === 4
                            ? `0${freeschedule}`
                            : `${freeschedule}`
                        }:00-03:00`
                    ] !== undefined
                        ? bookingHours[dateState][
                        `${freeschedule.length === 4
                            ? `0${freeschedule}`
                            : `${freeschedule}`
                        }:00-03:00`
                        ]
                        : 0
                    : 0
            );
            setHourSelect(freeschedule);
        }
    };

    useEffect(() => {
        initialSelectDay();
    }, []);

    return (
        <>
            <h3 className="TitleTurnos">Seleccione la hora de su visita</h3>

            <div className="contienerSchedule">
                {horarios.map((item) => (
                    <button
                        key={item}
                        disabled={`${bookingHours[dateState]
                            ? bookingHours[dateState][
                                `${item.length === 4 ? `0${item}` : `${item}`}:00-03:00`
                            ] >= 3
                                ? () => {
                                    setHourSelect(getNextElement(item));
                                    return true;
                                }
                                : time >=
                                    new Date(
                                        `${dateState}T${item.length === 4 ? `0${item}` : `${item}`
                                        }:00-03:00`
                                    )
                                    ? () => {
                                        setHourSelect(getNextElement(item));
                                        return true;
                                    }
                                    : ""
                            : time >=
                                new Date(
                                    `${dateState}T${item.length === 4 ? `0${item}` : `${item}`
                                    }:00-03:00`
                                )
                                ? () => {
                                    setHourSelect(getNextElement(item));
                                    return true;
                                }
                                : ""
                            }`}
                        className={` ${HourSelect === (item.length === 4 ? `0${item}` : `${item}`)
                            ? "Select"
                            : ""
                            }`}
                        onClick={() => {
                            setHourSelect(`${item.length === 4 ? "0" : ""}${item}`);
                            setPersonsInThatHour(
                                bookingHours[dateState]
                                    ? bookingHours[dateState][
                                        `${item.length === 4 ? `0${item}` : `${item}`}:00-03:00`
                                    ] !== undefined
                                        ? bookingHours[dateState][
                                        `${item.length === 4 ? `0${item}` : `${item}`}:00-03:00`
                                        ]
                                        : 0
                                    : 0
                            );

                        }}
                    >
                        {item}
                    </button>
                ))}
            </div>
            <div className="conteinerRef">
                <div className="circleRefConteiner available">
                    <div className="circle"></div>
                    Horario disponible
                </div>
                <div className="circleRefConteiner disabled">
                    <div className="circle"></div>
                    Horario ocupado
                </div>
                <div className="circleRefConteiner Select">
                    <div className="circle"></div>
                    Horario seleccionado
                </div>
            </div>
            <div className="conteinerBottons">
                <button
                    onClick={() => {
                        pageNavigation("calendar");
                    }}
                    className="back"
                >
                    Volver
                </button>

                <button
                    disabled={HourSelect === null ? true : ""}
                    onClick={() => {
                        pageNavigation("userinfo");
                    }}
                    className="next"
                >
                    Siguente
                </button>
            </div>
        </>
    );
};

export default Schedule;
