import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CalendarComponent,
  Schedule,
  UserInfo,
  Confirmation,
  ErrorBooking
} from './index.jsx';

import ReactLoading from "react-loading";
import { useStore } from "../store/Store";

// const url = "https://lgp-backend-zeta.vercel.app/api";
const url = "http://localhost:3500/api"

const MainTurnos = () => {
  const { dateState, HourSelect, getData } = useStore((state) => state);
  const [loading, setloading] = useState(true);
  const [keyword, setkeyword] = useState("");
  const [StartDay, setStartDay] = useState("");

  const [ProgressBarState, setProgressBarState] = useState({
    1: {
      active: true,
      finish: false,
    },
    2: {
      active: false,
      finish: false,
    },
    3: {
      active: false,
      finish: false,
    },
  });
  const [pageState, setpageState] = useState("calendar");
  const [insertData, setinsertData] = useState(null);

  const [Errortxts, setErrortxts] = useState({
    title: "",
    ErrorDescripto: "",
  });

  useEffect(() => {

    getData()
    setloading(false);
  }, []);

  const pageNavigation = (destination, context) => {
    switch (destination) {
      case "calendar":
        setpageState("calendar");
        setProgressBarState({
          1: {
            active: true,
            finish: false,
          },
          2: {
            active: false,
            finish: false,
          },
          3: {
            active: false,
            finish: false,
          },
        });
        break;
      case "schedule":
        setpageState("schedule");
        setProgressBarState({
          1: {
            active: false,
            finish: true,
          },
          2: {
            active: true,
            finish: false,
          },
          3: {
            active: false,
            finish: false,
          },
        });
        break;
      case "userinfo":
        setpageState("userinfo");
        setProgressBarState({
          1: {
            active: false,
            finish: true,
          },
          2: {
            active: false,
            finish: true,
          },
          3: {
            active: true,
            finish: false,
          },
        });
        break;
      case "confirmation":
        setpageState("confirmation");
        setProgressBarState({
          1: {
            active: false,
            finish: true,
          },
          2: {
            active: false,
            finish: true,
          },
          3: {
            active: false,
            finish: true,
          },
        });
        break;
      case "errorBooking":
        setProgressBarState({
          1: {
            active: false,
            finish: false,
          },
          2: {
            active: false,
            finish: false,
          },
          3: {
            active: false,
            finish: false,
          },
        });
        setErrortxts({
          title: context.errTitle,
          ErrorDescripto: context.errMsg,
        });
        setpageState("errorBooking");
        break;
      default:
        break;
    }
  };

  const sendInsert = async (
    ClientName,
    ClientPhone,
    ClientEmail,
    ClientPartners
  ) => {
    setloading(true);
    function sumarTresHorasAFecha(fecha) {
      const fechaClonada = new Date(fecha);
      fechaClonada.setHours(fechaClonada.getHours() + 3);
      return fechaClonada;
    }

    let HourToBooking = sumarTresHorasAFecha(
      new Date(`${dateState}T${HourSelect}:00.000Z`)
    );

    const res = await axios.post(`${url}/insertNewBooking`, {
      ClientName,
      ClientPhone,
      ClientEmail,
      ClientPartners,
      HourToBooking,
      ApiKey: process.env.REACT_APP_APIKEY,
    });
    if (!res.data.err) {
      setinsertData(res.data);
      setkeyword(res.data.keyWord);
      setStartDay(HourToBooking);
      pageNavigation("confirmation");
    } else {
      pageNavigation("errorBooking", res.data);
    }
    setloading(false);
  };

  const renderSection = () => {
    switch (pageState) {
      case "calendar":
        return (
          <CalendarComponent
            pageNavigation={pageNavigation}
          />
        );
      case "schedule":
        return (
          <Schedule
            pageNavigation={pageNavigation}
          />
        );
      case "userinfo":
        return (
          <UserInfo
            pageNavigation={pageNavigation}
            sendInsert={sendInsert}
          />
        );
      case "confirmation":
        return (
          <Confirmation
            insertData={insertData}
            StartDay={StartDay}
            keyword={keyword}
            pageNavigation={pageNavigation}
          />
        );
      case "errorBooking":
        return <ErrorBooking Errortxts={Errortxts} />;

      default:
        return <>er</>;
    }
  };

  if (loading) {
    return (
      <main className="MainTurnos loading">
        <ReactLoading type={"spin"} color="#AE891E" />
      </main>
    );
  } else {
    return (
      <main
        className={`MainTurnos ${pageState === "schedule" ? "schedule" : ""} `}
      >
        <div className="ProgressBarConteiner">
          <h2>Reserve su turno</h2>
          <div className="numberBlock">
            <div
              className={`numberProgressBar
            ${ProgressBarState[1].active ? "Active" : ""} 
            ${ProgressBarState[1].finish ? "Finish" : ""} 
            `}
            >
              <div className="FinishEfect">
                <img src="/imgs/mdi_check.svg" alt="" srcset="" />
              </div>
              <div>1</div>
              <div className="nameProgressBarButton">Día</div>
              <div className="lineOutside"></div>
            </div>

            <div
              className={`numberProgressBar ${ProgressBarState[2].active ? "Active" : ""
                }  ${ProgressBarState[2].finish ? "Finish" : ""}  `}
            >
              <div className="FinishEfect">
                <img src="/imgs/mdi_check.svg" alt="" srcset="" />
              </div>
              <div>2</div>
              <div className="nameProgressBarButton">Hora</div>
              <div className="lineOutside"></div>
            </div>
            <div
              className={`numberProgressBar
            ${ProgressBarState[3].active ? "Active" : ""} 
            ${ProgressBarState[3].finish ? "Finish" : ""} 
            `}
            >
              <div className="FinishEfect">
                <img src="/imgs/mdi_check.svg" alt="" srcset="" />
              </div>
              <div>3</div>
              <div className="nameProgressBarButton">Datos</div>
            </div>
          </div>
        </div>
        <div
          className={`TurnoMainSection ${pageState === "schedule" || pageState === "userinfo"
            ? "extended"
            : ""
            }
        ${pageState === "confirmation" ? "small" : ""}`}
        >
          {renderSection()}
        </div>
      </main>
    );
  }
};

export default MainTurnos;
