import React from "react";

import { useNavigate } from "react-router-dom";


const ErrorBooking = ({ Errortxts }) => {
    const navigate = useNavigate();

    return (
        <>
            <h2 className="TitleTurnos">{Errortxts.title}</h2>

            <h3 style={{ textAlign: 'center', maxWidth: '50%', fontSize: '2vh' }}>{Errortxts.ErrorDescripto}
            </h3>
            <button onClick={navigate(0)} className="CallToAction">

                <div onClick={navigate(0)} className="errorBackEfect">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5013 12C22.5013 12.1989 22.4223 12.3897 22.2817 12.5304C22.141 12.671 21.9503 12.75 21.7513 12.75L4.06184 12.75L8.78234 17.469C8.92317 17.6099 9.00229 17.8009 9.00229 18C9.00229 18.1992 8.92317 18.3902 8.78234 18.531C8.64151 18.6719 8.45051 18.751 8.25134 18.751C8.05218 18.751 7.86117 18.6719 7.72034 18.531L1.72034 12.531C1.6505 12.4614 1.59508 12.3786 1.55727 12.2875C1.51946 12.1964 1.5 12.0987 1.5 12C1.5 11.9014 1.51946 11.8037 1.55727 11.7126C1.59508 11.6215 1.6505 11.5387 1.72034 11.469L7.72034 5.46903C7.86117 5.3282 8.05218 5.24908 8.25134 5.24908C8.45051 5.24908 8.64151 5.3282 8.78234 5.46903C8.92317 5.60986 9.00229 5.80087 9.00229 6.00003C9.00229 6.19919 8.92317 6.3902 8.78234 6.53103L4.06184 11.25L21.7513 11.25C21.9503 11.25 22.141 11.329 22.2817 11.4697C22.4223 11.6104 22.5013 11.8011 22.5013 12Z" fill="black" />
                    </svg>


                    <>&nbsp;</>
                    VOLVER A RESERVAR

                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5013 12C22.5013 12.1989 22.4223 12.3897 22.2817 12.5304C22.141 12.671 21.9503 12.75 21.7513 12.75L4.06184 12.75L8.78234 17.469C8.92317 17.6099 9.00229 17.8009 9.00229 18C9.00229 18.1992 8.92317 18.3902 8.78234 18.531C8.64151 18.6719 8.45051 18.751 8.25134 18.751C8.05218 18.751 7.86117 18.6719 7.72034 18.531L1.72034 12.531C1.6505 12.4614 1.59508 12.3786 1.55727 12.2875C1.51946 12.1964 1.5 12.0987 1.5 12C1.5 11.9014 1.51946 11.8037 1.55727 11.7126C1.59508 11.6215 1.6505 11.5387 1.72034 11.469L7.72034 5.46903C7.86117 5.3282 8.05218 5.24908 8.25134 5.24908C8.45051 5.24908 8.64151 5.3282 8.78234 5.46903C8.92317 5.60986 9.00229 5.80087 9.00229 6.00003C9.00229 6.19919 8.92317 6.3902 8.78234 6.53103L4.06184 11.25L21.7513 11.25C21.9503 11.25 22.141 11.329 22.2817 11.4697C22.4223 11.6104 22.5013 11.8011 22.5013 12Z" fill="white" />
                </svg>

                <>&nbsp;</>
                VOLVER A RESERVAR
            </button >
        </>
    )
}

export default ErrorBooking