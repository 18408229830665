import { create } from "zustand";
import axios from "axios";

import { subscribeWithSelector } from "zustand/middleware";

const url = "https://lgp-backend-zeta.vercel.app/api";
// const url = "http://localhost:3500/api";

export const useStore = create()(
    subscribeWithSelector((set, get) => ({
        dateState: new Date(),
        personsInThatHour: 0,
        HourSelect: "9:00",
        userData: { ClientName: "", ClientPhone: "", ClientEmail: "", ClientPartners: "" },
        bookingHours: [],
        disabledDates: [],

        setuserData: (Object) => {
            let newObject = Object;
            set(() => ({ userData: newObject }));
        },

        setHourSelect: (Text) => {
            let newText = Text;
            set(() => ({ HourSelect: newText }));
        },

        setPersonsInThatHour: (number) => {
            let newNumber = number;
            set(() => ({ personsInThatHour: newNumber }));
        },

        setDateState: (date) => {
            let newDate = new Date(date).toISOString().split("T")[0];
            set(() => ({ dateState: newDate }));
        },
        getData: async () => {
            let data = [];
            const res = await axios.get(`${url}/getdata`, {
                params: { ApiKey: process.env.REACT_APP_APIKEY },
            });

            for (let i = 0; i < res.data.fullBookingDay.length; i++) {
                const element = res.data.fullBookingDay[i];
                data.push(new Date(element));
            }
            set(() => ({ bookingHours: res.data.ParcialBooking }));
            set(() => ({ disabledDates: data }));
        },
    }))
);
