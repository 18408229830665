import { useSearchParams } from "react-router-dom";
import { Header, Footer } from '../componets'

import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";


function AddToCalendar() {
    let [Params, setParams] = useSearchParams();
    let [fecha, setfecha] = useState('')
    let [keyword, setkeyword] = useState('')
    const [userCalendarAdd, setuserCalendarAdd] = useState(false)
    let [data, setData] = useState(false)
    useEffect(() => {
        try {
            function formatearFecha(fecha) {
                const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
                const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

                const diaSemana = diasSemana[fecha.getDay()];
                const diaMes = fecha.getDate();
                const mes = meses[fecha.getMonth()];
                const hora = fecha.getHours();
                const minutos = fecha.getMinutes();

                const fechaFormateada = `${diaSemana} ${diaMes} de ${mes} a las ${hora}.${String(minutos).length === 1 ? '0' : ''}${minutos} hs`;

                return fechaFormateada;
            }
            const obj = {}
            const QueryParams = (Params.toString()).split('&')
            for (let i = 0; i < QueryParams.length; i++) {
                let helper = {}
                const element = QueryParams[i].split('=');
                if (element[0] === 'start' || element[0] === 'end') {
                    let arr = (element[1].split('%26'))
                    for (let index = 0; index < arr.length; index++) {
                        const element = arr[index].replaceAll('%252F', '/')
                            .replaceAll('%253A', ':').replaceAll('%2528', '(').replaceAll('%2529', ')')
                            .replaceAll('%2B', ' ').split('%3D');
                        helper[element[0]] = element[1]
                    }
                    obj[element[0]] = helper
                } else {
                    obj[element[0]] = element[1].replaceAll('+', ' ').replaceAll('%3A', ':').replaceAll('%0A', ' ')
                    if (element[0] === 'description') {
                        setkeyword(element[1].replaceAll('+', ' ').replaceAll('%3A', ':').replaceAll('%0A', ' ').split('  Palabra de seguridad ')[1])
                    }


                }
            }
            obj.end.dateTime = new Date(obj.end.dateTime)
            obj.start.dateTime = new Date(obj.start.dateTime)
            setData(obj)
            setfecha(formatearFecha(new Date(obj.start.dateTime)))
        } catch (error) {
            window.location.href = "https://www.legrandpatron.com.ar";
        }
    }, [])
    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const InsertEvent = await axios.post('https://lgp-backend-zeta.vercel.app/ClientEventCreate/', {
                code, event: data, keyWord: keyword
            });
            if (!InsertEvent.err) setuserCalendarAdd(true)
        },
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar'
    });

    return (
        <>
            <Header />
            <main style={{ backgroundColor: '#121212', height: '60vh', padding: '5vh', display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2 className="TitleTurnos">Tu turno</h2>
                    <div className="Aclaracion" style={{ textAlign: 'center' }}>
                        Al reservar su turno en nuestras instalaciones<b> asume el compromiso
                            de presentarse en el día y horario solicitado.</b> Le Grand Patron se reserva los derechos de admisión.
                    </div>
                    <div className="cardConfirm">
                        <div className="ConfirmTitle">
                            SU PALABRA DE ACCESO ES
                        </div>
                        <div className="KeyWord">{keyword}</div>
                        <h3>Detalle de la reserva</h3>
                        <div className="confirmDetalle">
                            {fecha}
                        </div>
                        <div className="confirmDetalle">
                            Suipacha 936, Ciudad Autónoma de Buenos Aires
                        </div>


                    </div>
                    <div className="conteinerBottons confirmation" style={{ width: '100%' }}>
                        {userCalendarAdd ? (
                            <button disabled={true} className="next ">
                                Cita agregada
                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    googleLogin();
                                }}
                                className="next googleBrand"
                                style={{ width: '100%' }}
                            >
                                <img src="/imgs/Google_Calendar_icon.png"></img>
                                Añadir cita al calendario
                            </button>
                        )}



                    </div>
                </div>

            </main>
            <Footer />
        </>
    );
}

export default AddToCalendar