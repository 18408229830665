import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../calendar.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../store/Store";

const CalendarComponent = ({ pageNavigation }) => {
    const { setDateState, disabledDates } = useStore((state) => state);
    let today = new Date();
    const [calendarSelect, setcalendarSelect] = useState(
        today.getDay() === 0 ? today.setDate(today.getDate() + 1) : new Date()
    );

    const changeDate = (e) => {
        setcalendarSelect(e);
    };

    const NextPage = () => {
        pageNavigation("schedule");
        setDateState(calendarSelect);

    }


    return (
        <>
            <h3 className="TitleTurnos">¿Qué día desea reservar?</h3>

            <Calendar
                value={calendarSelect}
                onChange={changeDate}
                minDate={new Date()}
                prev2Label={null}
                next2Label={null}
                minDetail={"month"}
                navigationLabel={({ date, label, locale, view }) => {
                    let mes = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
                        new Date(date)
                    );
                    return `${mes.charAt(0).toUpperCase() + mes.slice(1)
                        } de ${date.getFullYear()}`;
                }}
                tileDisabled={({ date, view }) => {
                    return (
                        disabledDates.some(
                            (disabledDate) =>
                                date.getFullYear() === disabledDate.getFullYear() &&
                                date.getMonth() === disabledDate.getMonth() &&
                                date.getDate() === disabledDate.getDate()
                        ) || date.getDay() === 0
                    );
                }}
            />
            <div className="conteinerBottons">
                <button className="back">
                    <Link to={"/"}>Volver</Link>
                </button>

                <button
                    onClick={NextPage}
                    className="next "
                >
                    Siguente
                </button>
            </div>
        </>
    );
};

export default CalendarComponent;
