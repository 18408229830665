import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './App.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Home, Turnos, Error, PrivacyPolicy, AddToCalendar } from './pages'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,

  },
  {
    path: "turno",
    element: <Turnos />,
  },
  {
    path: "/Politica-de-Privacidad",
    element: <PrivacyPolicy />,
  },
  {
    path: "/agregar-al-calendario",
    element: <AddToCalendar />,
  },
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="855892031037-gbhbkj8q9d0qklc099i3dkcufehuk5c9.apps.googleusercontent.com">
      <RouterProvider router={router} />

    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
