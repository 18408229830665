import React from "react";
import { Header, Footer, MainTurnos } from '../componets'


const Turnos = () => {
  return (
    <>
      <Header />
      <MainTurnos />

      <Footer />

    </>
  );


};

export default Turnos;
