import { useState } from "react";
import { useStore } from "../store/Store";

const UserInfo = ({
    pageNavigation,
    sendInsert,
}) => {
    const { personsInThatHour, userData, setuserData } = useStore((state) => state);

    const [username, setUsername] = useState(userData.ClientName);
    const [Phone, setPhone] = useState(userData.ClientPhone);
    const [Email, setEmail] = useState(userData.ClientEmail);
    const [checkboxes, setCheckboxes] = useState([
        { id: "opcion1", label: "Solo yo", checked: true, cant: 1 },
        { id: "opcion2", label: "Con 1 persona", checked: false, cant: 2 },
        { id: "opcion3", label: "Con 2 personas", checked: false, cant: 3 },
    ]);

    const handleCheckboxChange = (checkboxId) => {
        const updatedCheckboxes = checkboxes.map((checkbox) => ({
            ...checkbox,
            checked: checkbox.id === checkboxId,
        }));

        setCheckboxes(updatedCheckboxes);
    };

    const [errUsername, seterrUsername] = useState(false);
    const [errEmail, seterrEmail] = useState(false);
    const [errPhone, seterrPhone] = useState(false);

    const nextButton = async () => {
        if (!errUsername && !errEmail && !errPhone) {
            let err = false;
            if (username === "") {
                seterrUsername(true);
                setTimeout(() => {
                    seterrUsername(false);
                }, 3000);
                err = true;
            }
            if (Phone === "") {
                seterrPhone(true);
                setTimeout(() => {
                    seterrPhone(false);
                }, 3000);
                err = true;
            }
            if (Email === "") {
                seterrEmail(true);
                setTimeout(() => {
                    seterrEmail(false);
                }, 3000);
                err = true;
            }

            if (!Email.includes("@")) {
                setEmail("");
                seterrEmail(true);
                setTimeout(() => {
                    seterrEmail(false);
                }, 3000);
                err = true;
            }

            if (err) {
                return null;
            }

            const ClientPartners = checkboxes[0].checked
                ? "Solo"
                : checkboxes[1].checked
                    ? checkboxes[1].label
                    : checkboxes[2].checked
                        ? checkboxes[2].label
                        : "";

            setuserData({
                ClientName: username,
                ClientPhone: Phone,
                ClientEmail: Email,
                ClientPartners,
            });
            sendInsert(username, Phone, Email, ClientPartners);
        }
    };

    return (
        <>
            <h3 className="TitleTurnos">Datos de contacto</h3>
            <div className="continerForm">
                <div className="twoinputconteiner">
                    <div className="continerInput">
                        <label htmlFor="Nombre">Nombre y apellido*</label>

                        <input
                            value={username}
                            onChange={(e) => {
                                if (errUsername) seterrUsername(false);
                                setUsername(e.target.value);
                            }}
                            type="text"
                            placeholder={`${errUsername ? "Campo obligatorio " : "Coloque su nombre"
                                }`}
                            className={`inputText ${errUsername ? "err" : ""}`}
                        />
                    </div>
                    <div className="continerInput">
                        <label htmlFor="Numero de telefono">Teléfono de contacto*</label>
                        <input
                            value={Phone}
                            onChange={(e) => {
                                if (errPhone) seterrPhone(false);
                                setPhone(e.target.value);
                            }}
                            type="tel"
                            placeholder={`${errPhone ? "Campo obligatorio " : "+54 9 11 3543 1234"
                                }`}
                            className={`inputText ${errPhone ? "err" : ""}`}
                        />
                    </div>
                </div>

                <label htmlFor="Correo electrónico">Correo electrónico*</label>

                <input
                    value={Email}
                    onChange={(e) => {
                        if (errEmail) seterrEmail(false);
                        setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder={`${errEmail ? "Campo obligatorio " : "sumail@mail.com.ar"
                        }`}
                    className={`inputText ${errEmail ? "err" : ""}`}
                />

                <label style={{ marginTop: "1%" }}>*Campo obligatorio</label>

                <h4>
                    ¿Cuántas personas nos visitan? Las reservas tienen capacidad máxima de
                    3 personas.
                </h4>
                <div className="conteinerCheckbox">
                    {checkboxes.map((checkbox) => (
                        <button
                            onClick={() =>
                                checkbox.cant + personsInThatHour <= 3
                                    ? handleCheckboxChange(checkbox.id)
                                    : ""
                            }
                            className="untiCheboxAndLabel"
                            key={checkbox.id}
                        >
                            <input
                                id={checkbox.id}
                                type="checkbox"
                                checked={checkbox.checked}
                                className={"checkbox"}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                                disabled={checkbox.cant + personsInThatHour <= 3 ? false : true}
                            />
                            <label
                                className={`${checkbox.cant + personsInThatHour <= 3 ? "" : "disabled"}`}
                            >
                                {checkbox.label}
                            </label>
                        </button>
                    ))}
                </div>
            </div>
            <div className="conteinerFormMovil">
                <label htmlFor="Nombre">Nombre y apellido*</label>

                <input
                    value={username}
                    onChange={(e) => {
                        if (errUsername) seterrUsername(false);
                        setUsername(e.target.value);
                    }}
                    type="text"
                    placeholder={`${errUsername ? "Campo obligatorio " : "Coloque su nombre"
                        }`}
                    className={`inputText ${errUsername ? "err" : ""}`}
                />

                <label htmlFor="Numero de telefono">Teléfono de contacto*</label>

                <input
                    value={Phone}
                    onChange={(e) => {
                        if (errPhone) seterrPhone(false);
                        setPhone(e.target.value);
                    }}
                    type="tel"
                    placeholder={`${errPhone ? "Campo obligatorio " : "+54 9 11 3543 1234"
                        }`}
                    className={`inputText ${errPhone ? "err" : ""}`}
                />

                <label htmlFor="Correo electrónico">Correo electrónico*</label>

                <input
                    value={Email}
                    onChange={(e) => {
                        if (errEmail) seterrEmail(false);
                        setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder={`${errEmail ? "Campo obligatorio " : "sumail@mail.com.ar"
                        }`}
                    className={`inputText ${errEmail ? "err" : ""}`}
                />

                <label style={{ marginTop: "1%" }}>*Campo obligatorio</label>

                <h4>
                    ¿Cuántas personas nos visitan? Las reservas tienen capacidad máxima de
                    3 personas.
                </h4>
                <div className="conteinerCheckbox">
                    {checkboxes.map((checkbox) => (
                        <button
                            onClick={() =>
                                checkbox.cant + personsInThatHour <= 3
                                    ? handleCheckboxChange(checkbox.id)
                                    : ""
                            }
                            className="untiCheboxAndLabel"
                            key={checkbox.id}
                        >
                            <input
                                id={checkbox.id}
                                type="checkbox"
                                checked={checkbox.checked}
                                className={"checkbox"}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                                disabled={checkbox.cant + personsInThatHour >= 3 ? true : false}
                            />
                            <label
                                className={`${checkbox.cant + personsInThatHour >= 3 ? "disabled" : ""}`}
                                key={checkbox.id}>
                                {checkbox.label}
                            </label>
                        </button>
                    ))}
                </div>
            </div>
            <div className="Aclaracion">
                Al reservar su turno en nuestras instalaciones
                <b style={{ fontWeight: 500 }}>
                    {" "}
                    asume el compromiso de presentarse en el día y horario solicitado.
                </b>{" "}
                Le Grand Patron se reserva los derechos de admisión.
            </div>

            <div className="conteinerBottons">
                <button
                    onClick={() => {
                        pageNavigation("schedule");
                    }}
                    className="back"
                >
                    Volver
                </button>

                <button
                    onClick={() => {
                        nextButton();
                    }}
                    className="next"
                >
                    Reservar cita
                </button>
            </div>
        </>
    );
};

export default UserInfo;
